const __request = require(`./__request`);

// 获取最近访问的城市历史：http://tour-alpha.yinzhilv.com/doc.html#/yinzhilv_travel/home-config-controller/getHotCityHistoryUsingPOST
const web_common_visitor_getHotCityHistory = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/web/common/visitor/getHotCityHistory',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = web_common_visitor_getHotCityHistory;