import _ from 'underscore';
import extend from "extend";
import to_string from 'nllib/src/convert/to_string';

// 热门城市
import web_common_visitor_searchHotCity from '@/lib/data-service/default/web_common_visitor_searchHotCity'
// 获取省级
import web_common_area_getAreaDataByAreaTypeResponse from '@/lib/data-service/default/web_common_area_getAreaDataByAreaTypeResponse'
// 根据省级id获取城市
import getDomestic from '@/lib/data-service/default/web_common_area_getInlandAreaChildListByParentIdResponse'
// 获取所有城市
import web_common_area_getStoreAreaTree from '@/lib/data-service/default/web_common_area_getStoreAreaTree'
// 获取最近访问的城市历史
import web_common_visitor_getHotCityHistory from '@/lib/data-service/default/web_common_visitor_getHotCityHistory'
// 保存用戶的城市搜索记录
import web_common_visitor_saveHotCityHistory from '@/lib/data-service/default/web_common_visitor_saveHotCityHistory'


export default {
    data() {
        return {
            loading: false,
            provinceId: '',
            cityId: '',
            search_input: '',
            hot_city_list: [],
            history_city_list: [],
            province_list: [],
            city_list: [],
            source_city_list: [], // 存储一份数据源
            all_entity_list: [],

            pinYinList: ['ABCD', 'EFGH', 'IJKL', 'MNOP', 'QRST', 'UVWX', 'YZ'],
            listActive: '',
            siteObj: {},
            siteActiveList: {},//选中的城市列表
            siteShow: true,//搜索地址显示
            searchSite: [],//搜索后地址
            itemShow: 0,
            warningShow: false,
        }
    },
    methods: {
        get_hot_city() { // 获取热门城市
            const __this = this;
            web_common_visitor_searchHotCity().then(res => {
                __this.hot_city_list = res.hotCitys;
            })
        },
        get_history_city() { // 获取热门城市
            const __this = this;
            web_common_visitor_getHotCityHistory().then(res => {
                __this.history_city_list = res.citys;
            })
        },
        get_province() { // 获取省级列表
            const __this = this;
            web_common_area_getAreaDataByAreaTypeResponse({areaType: 1}).then(res => { // areaType: 1为国内 ,2为国际
                __this.province_list = res.areaTreeResult;
            })
        },
        get_all_city() { // 获取全部城市列表
            const __this = this;
            web_common_area_getStoreAreaTree().then(res => {
                __this.source_city_list = res.storeAreaTreeResults;
                __this.all_entity_list = res.storeAreaTreeResults;
                __this.get_list(__this.all_entity_list);
            })
        },

        //点击切换
        get_active(item, index) {
            const __this = this;
            let arr = item.split('');
            let newSite = {};
            if (arr.length > 0) {
                __this.listActive = index;
                arr.map(item => {
                    for (let site in __this.siteObj) {
                        if (item == site) {
                            newSite[item] = __this.siteObj[site]
                        }
                    }
                });
                __this.siteActiveList = newSite;
            } else {
                __this.siteActiveList = __this.siteObj;
            }
            console.log(__this.siteActiveList)
        },
        //生成城市列表
        get_list(val) {
            const __this = this;
            const list = val;
            const siteArr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
            let siteObj = {};
            //排列城市列表
            siteArr.map(letter => {
                siteObj[letter] = list.filter(item => {
                    return letter === item.spellInitial.charAt(0)
                })
            });

            // let hasCityArr = [];
            // for(let arrItem in siteObj) {
            //     hasCityArr = [].concat(siteObj[arrItem]);
            // }
            // let siteStr = '', siteIndex = 0;
            // if (hasCityArr.length > 0) {
            //     __this.pinYinList.forEach((site, index) => {
            //         if (site.indexOf(hasCityArr[0].spellInitial.charAt(0)) != -1) {
            //             siteStr = site;
            //             siteIndex = index;
            //         }
            //     });
            // }
            __this.siteObj = siteObj;
            // __this.get_active(siteStr, siteIndex);
            __this.siteActiveList = __this.siteObj;
        },
        select_province() { // 省份选择
            const __this = this;
            __this.search_input = '';
            __this.cityId = '';
            __this.listActive = '';
            __this.city_list = [];
            if (__this.provinceId) {
                getDomestic({id: __this.provinceId}).then(res => {
                    __this.city_list = res.areaTreeResult;
                    __this.all_entity_list = __this.city_list;
                    __this.get_list(__this.all_entity_list);
                })
            } else {
                __this.cityId = '';
                __this.get_list(__this.source_city_list);
            }

        },
        select_city() { // 城市选择
            const __this = this;
            __this.search_input = '';
            __this.listActive = '';
            if (__this.cityId) {
                let cityArr = [];
                __this.city_list.forEach(val => {
                    if (val.id === __this.cityId) {
                        cityArr.push(val);
                    }
                });
                __this.all_entity_list = cityArr;
                __this.get_list(__this.all_entity_list);
            } else {
                __this.get_list(__this.city_list);
            }
        },

        __input_keyup_event_handler($event) { // 直接搜素
            const __this = this;
            const code = $event.code;
            const __input = (__this.search_input || ``).toLowerCase();
            let cityArr = [];
            if (!_.contains([`ArrowDown`, `ArrowUp`, `ArrowLeft`, `ArrowRight`, `Enter`], code)) {
                //不是特殊按键，进行初始化数据源
                //特殊按键：向下箭头、向上箭头，回车
                __this.all_entity_list.forEach((val) => {
                    if (val.areaName.indexOf(__input) > -1 || val.spell.toLowerCase().indexOf(__input) > -1 || val.spellInitial.toLowerCase().indexOf(__input) > -1) {
                        cityArr.push(val);
                    }
                })
            }
            __this.listActive = '';
            __this.get_list(cityArr);
        },
        //点击城市
        getSiteItem(item) {
            const __this = this;
            const params = {
                cityCode: item.cityCode,
                cityName: item.areaName,
                cityId: Number(item.id)
            };
            __this.$store.commit('set_local_city', params);
            sessionStorage.setItem("localCity", JSON.stringify(params));
            __this.loading = true;
            web_common_visitor_saveHotCityHistory(params).finally(res => {
                __this.loading = false;
                __this.$router.go(-1);
            });
        },
        hotSiteItem(item) { // 热门城市
            const __this = this;
            const params = {
                cityCode: item.cityCode,
                cityName: item.cityName,
                cityId: Number(item.cityId)
            };
            __this.$store.commit('set_local_city', params);
            sessionStorage.setItem("localCity", JSON.stringify(params));
            __this.loading = true;
            web_common_visitor_saveHotCityHistory(params).finally(res => {
                __this.loading = false;
                __this.$router.go(-1);
            });
        }
    },
    created() {
        const __this = this;
        __this.get_hot_city();
        __this.get_history_city();
        __this.get_province();
        __this.get_all_city();
    },
    watch: {

    },
}