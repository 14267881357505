const __request = require(`./__request/__request_contentType_json`);

// 保存用戶的城市搜索记录：http://tour-alpha.yinzhilv.com/doc.html#/yinzhilv_travel/home-config-controller/saveHotCityHistoryUsingPOST
const web_common_visitor_saveHotCityHistory = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/web/common/visitor/saveHotCityHistory',
        data: pParameter,
        enable_error_alert: false
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = web_common_visitor_saveHotCityHistory;