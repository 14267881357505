const __request = require(`./__request`);

// 接口文档地址：http://219.128.52.2:9999/doc.html#/yinzhilv_travel/area-controller/getAreaDataByAreaTypeResponseUsingPOST
const web_common_area_getAreaDataByAreaTypeResponse = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/web/common/area/getAreaDataByAreaTypeResponse',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = web_common_area_getAreaDataByAreaTypeResponse;