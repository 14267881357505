const __request = require(`./__request`);

// 热门城市：http://219.128.52.2:9999/doc.html#/yinzhilv_travel/home-config-controller/searchHotCityUsingPOST
const web_common_visitor_searchHotCity = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/web/common/visitor/searchHotCity',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = web_common_visitor_searchHotCity;